<template>
  <v-btn
    :to="link"
    icon
    :color="btnColor"
  >
    <v-icon>
      {{ $vuetify.icons.values.launch }}
    </v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'TableButton',
  props: {
    link: { type: Object, default: () => { return {} } }
  },
  computed: {
    btnColor () {
      return typeof this.color === 'undefined' ? 'primary' : this.color
    }
  }
}
</script>
