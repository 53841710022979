<template>
  <v-col
    cols="12"
    sm="6"
    md="12"
    class="py-0"
  >
    <v-list>
      <v-list-group
        :prepend-icon="$vuetify.icons.values.tableSettings"
      >
        <template v-slot:activator>
          <v-list-item-title>
            {{ $t('general.columns') }}
          </v-list-item-title>
        </template>
        <v-list-item-group
          v-model="localSelectedColumns"
          multiple
        >
          <template v-for="item in headerKeys">
            <v-list-item
              :key="item"
              dense
              :value="item"
            >
              <template v-slot:default="{ active, toggle }">
                <v-list-item-action>
                  <v-checkbox
                    :input-value="active"
                    :true-value="item"
                    color="primary"
                    @click="toggle"
                  />
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t('documents.' + item) }}
                  </v-list-item-title>
                </v-list-item-content>
              </template>
            </v-list-item>
          </template>
        </v-list-item-group>
      </v-list-group>
    </v-list>
  </v-col>
</template>

<script>
export default {
  name: 'TableColumnsList',
  props: {
    headers: { type: Array, default: () => { return [] } },
    selectedHeaders: { type: Array, default: () => { return [] } }
  },
  computed: {
    headerKeys () {
      return this.headers.map(header => header.value)
    },
    localSelectedColumns: {
      get () {
        return this.selectedHeaders
      },
      set (list) {
        this.$emit('update:selected-headers', list)
        this.persistSelectedColumns(list)
      }
    }
  },
  mounted () {
    this.localSelectedColumns = JSON.parse(
      localStorage.getItem(
        this.$route.meta.viewContext +
        '-' +
        this.$route.params.documentCategory +
        '-columns-' +
        this.$route.params.workspaceId
      )
    ) || []
  },
  methods: {
    persistSelectedColumns (list) {
      localStorage.setItem(
        this.$route.meta.viewContext +
        '-' +
        this.$route.params.documentCategory +
        '-columns-' +
        this.$route.params.workspaceId, JSON.stringify(list)
      )
    }
  }
}
</script>
