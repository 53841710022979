<template>
  <v-row>
    <v-col
      cols="12"
      sm="6"
    >
      <custom-text-field
        v-model="form.docNum"
        hide-details
        :prepend-icon="$vuetify.icons.values.document"
        :label="$t('documents.docNum')"
        clearable
      />
    </v-col>
    <v-col
      v-if="showCategoriesFilter"
      cols="12"
      sm="6"
    >
      <custom-autocomplete
        v-model="form.docType"
        hide-details
        :prepend-icon="$vuetify.icons.values.category"
        :items="invoiceCategories"
        item-value="value"
        item-text="text"
        :label="$t('documents.docType')"
        :chips="$vuetify.breakpoint.mdAndUp"
        :deletable-chips="$vuetify.breakpoint.mdAndUp"
        :small-chips="$vuetify.breakpoint.mdAndUp"
        multiple
        clearable
      />
    </v-col>
    <v-col
      cols="12"
      sm="6"
    >
      <date-picker
        :date-picker-props="docDateFromProps"
        :data="form"
        :locale="locale"
        reference="docDateFrom"
        @close="docDateFromProps.menu = false"
      />
    </v-col>
    <v-col
      cols="12"
      sm="6"
    >
      <date-picker
        :date-picker-props="docDateToProps"
        :data="form"
        :locale="locale"
        reference="docDateTo"
        @close="docDateToProps.menu = false"
      />
    </v-col>
    <v-col
      cols="12"
      sm="6"
    >
      <date-picker
        :date-picker-props="createdAtFromProps"
        :data="form"
        :locale="locale"
        reference="createdAtFrom"
        @close="createdAtFromProps.menu = false"
      />
    </v-col>
    <v-col
      cols="12"
      sm="6"
    >
      <date-picker
        :date-picker-props="createdAtToProps"
        :data="form"
        :locale="locale"
        reference="createdAtTo"
        @close="createdAtToProps.menu = false"
      />
    </v-col>
    <v-col
      cols="12"
      sm="6"
    >
      <date-picker
        :date-picker-props="completedAtFromProps"
        :data="form"
        :locale="locale"
        reference="completedAtFrom"
        @close="completedAtFromProps.menu = false"
      />
    </v-col>
    <v-col
      cols="12"
      sm="6"
    >
      <date-picker
        :date-picker-props="completedAtToProps"
        :data="form"
        :locale="locale"
        reference="completedAtTo"
        @close="completedAtToProps.menu = false"
      />
    </v-col>
    <v-col
      v-if="context === 'sent'"
      cols="12"
      sm="6"
    >
      <custom-autocomplete
        v-model="form.acquisitionPipeline"
        hide-details
        :prepend-icon="$vuetify.icons.values.trendingDown"
        :loading="getPipelinesLoadingStatus"
        :items="activeWorkspacePipelines"
        item-value="id"
        item-text="name"
        :chips="$vuetify.breakpoint.mdAndUp"
        :deletable-chips="$vuetify.breakpoint.mdAndUp"
        :small-chips="$vuetify.breakpoint.mdAndUp"
        :label="$t('documents.acquisitionPipeline')"
        multiple
        clearable
      />
    </v-col>
    <v-col
      v-if="context === 'inbox'"
      cols="12"
      sm="12"
      lg="6"
    >
      <custom-autocomplete
        v-model="form.integrationPipeline"
        hide-details
        :prepend-icon="$vuetify.icons.values.trendingUp"
        :loading="getPipelinesLoadingStatus"
        :items="activeWorkspacePipelines"
        item-value="id"
        item-text="name"
        :chips="$vuetify.breakpoint.mdAndUp"
        :deletable-chips="$vuetify.breakpoint.mdAndUp"
        :small-chips="$vuetify.breakpoint.mdAndUp"
        :label="$t('documents.integrationPipeline')"
        multiple
        clearable
      />
    </v-col>
    <v-col
      cols="12"
      class="pb-0 text-subtitle-1 black--text"
    >
      {{ $t('general.status') }}
    </v-col>
    <v-col
      cols="12"
      class="pt-0"
    >
      <v-row>
        <v-checkbox
          v-model="form.status"
          value="error"
          prepend-icon=" "
          hide-details
          :label="$t('general.filters.errors')"
          color="primary"
        />
        <v-checkbox
          v-model="form.status"
          value="success"
          prepend-icon=" "
          hide-details
          :label="$t('general.filters.success')"
          color="primary"
        />
        <v-checkbox
          v-model="form.status"
          value="processing"
          prepend-icon=" "
          hide-details
          :label="$t('general.filters.processing')"
          color="primary"
        />
      </v-row>
    </v-col>
    <v-col
      v-if="additionalFiltersAreSelected"
      cols="12"
      class="text-subtitle-1 black--text"
    >
      {{ $t('general.filters.additional') }}
    </v-col>
    <template v-for="item in selectedFilters">
      <v-col
        :key="item"
        cols="12"
        sm="6"
      >
        <custom-text-field
          v-model="form[item]"
          prepend-icon=" "
          hide-details
          outlined
          :label="$t('documents.' + item)"
          clearable
        />
      </v-col>
    </template>
  </v-row>
</template>

<script>
import {
  props,
  getWorkspacePipelines,
  mounted,
  beforeDestroy,
  computedProperties,
  commonMethods
} from '@/mixins/filters'
import DatePicker from './picker/Date'
import CustomTextField from '@/components/inputs/CustomTextField'
import CustomAutocomplete from '@/components/inputs/CustomAutocomplete'

export default {
  name: 'DocumentsListFilters',
  components: {
    DatePicker,
    CustomTextField,
    CustomAutocomplete
  },
  mixins: [
    props,
    getWorkspacePipelines,
    mounted,
    beforeDestroy,
    computedProperties,
    commonMethods
  ],
  props: {
    selectedFilters: { type: Array, default: () => { return [] } }
  },
  data () {
    return {
      form: {
        docNum: null,
        docType: null,
        docDateFrom: null,
        docDateTo: null,
        createdAtFrom: null,
        createdAtTo: null,
        sourceFiles: null,
        integrationFiles: null,
        acquisitionPipeline: null,
        integrationPipeline: null,
        status: []
      },
      docDateFromProps: {
        menu: false,
        label: 'documents.docDateFrom'
      },
      docDateToProps: {
        menu: false,
        label: 'documents.docDateTo'
      },
      createdAtFromProps: {
        menu: false,
        label: 'documents.createdAtFrom'
      },
      createdAtToProps: {
        menu: false,
        label: 'documents.createdAtTo'
      },
      completedAtFromProps: {
        menu: false,
        label: 'documents.completedAtFrom'
      },
      completedAtToProps: {
        menu: false,
        label: 'documents.completedAtTo'
      }
    }
  },
  computed: {
    showCategoriesFilter () {
      return this.$route.params.documentCategory === 'invoices'
    },
    additionalFiltersAreSelected () {
      return this.selectedFilters.length > 0
    },
    invoiceCategories () {
      return [
        { text: this.$t('documentCategories.invoice'), value: 'invoice' },
        { text: this.$t('documentCategories.creditNote'), value: 'credit_note' },
        { text: this.$t('documentCategories.debitNote'), value: 'debit_note' },
        { text: this.$t('documentCategories.proformInvoice'), value: 'proform_invoice' }
      ]
    }
  }
}
</script>
