<template>
  <v-navigation-drawer
    v-model="sideSheetState"
    fixed
    floating
    temporary
    hide-overlay
    right
    :width="sideSheetWidth"
  >
    <global-events
      @keyup.esc="sideSheetState = false"
    />
    <v-toolbar
      flat
      class="pt-1"
    >
      <v-toolbar-title>
        <v-btn
          icon
          @click="sideSheetState = false"
        >
          <v-icon>
            {{ $vuetify.icons.values.close }}
          </v-icon>
        </v-btn>
        <slot name="title">
          {{ $t('general.settings') }}
        </slot>
      </v-toolbar-title>
    </v-toolbar>
    <v-container>
      <v-row>
        <slot name="columns" />
        <slot name="filters" />
      </v-row>
    </v-container>
  </v-navigation-drawer>
</template>

<script>
import GlobalEvents from 'vue-global-events'

export default {
  name: 'SettingsSideSheet',
  components: {
    GlobalEvents
  },
  props: {
    expandSettings: { type: Boolean, default: false }
  },
  data () {
    return {
      settings: []
    }
  },
  computed: {
    sideSheetState: {
      get () {
        return this.expandSettings
      },
      set (value) {
        this.$emit('update:expand-settings', value)
      }
    },
    sideSheetWidth () {
      return this.$vuetify.breakpoint.mdAndUp
        ? '30%'
        : '100%'
    }
  }
}
</script>
