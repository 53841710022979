<template>
  <v-container fluid>
    <v-row>
      <v-col
        cols="12"
        class="pt-0"
      >
        <settings-side-sheet
          :expand-settings.sync="expandSettings"
        >
          <template v-slot:columns>
            <table-columns-list
              :headers="headers"
              :selected-headers.sync="selectedHeaders"
            />
          </template>
          <template v-slot:filters>
            <table-filters-list
              :filters="filters"
              :selected-filters.sync="selectedFilters"
            />
          </template>
        </settings-side-sheet>
        <filters-side-sheet
          :expand-table-filters.sync="expandTableFilters"
          :loading-data="loadingData"
          :filters-are-active="filtersAreActive"
        >
          <template v-slot:title>
            {{ $t('filters.documents') }}
          </template>
          <template v-slot:filters>
            <documents-list-filters
              :context="viewContext"
              :loading-data="loadingData"
              :selected-filters="selectedFilters"
              :expand-table-filters.sync="expandTableFilters"
              :active-filters.sync="activeFilters"
            />
          </template>
        </filters-side-sheet>
        <table-toolbar
          :context="tableToolbarContext"
          :loading-data="loadingData"
          :expand-table-filters.sync="expandTableFilters"
          :expand-settings.sync="expandSettings"
          :filters-are-active="filtersAreActive"
          @refreshData="getTableData"
        />
        <card-layout
          title-class="caption"
          content-class="pt-0"
          :disable-title="!filtersAreActive"
        >
          <template v-slot:title>
            <filter-chips :active-filters="activeFilters">
              <template v-slot:label="{ item }">
                {{ $t('documents.' + item) }}
              </template>
            </filter-chips>
          </template>
          <template v-slot:content>
            <v-data-table
              :loading="loadingData"
              :headers="visibleHeaders"
              :style="tableWrapStyle"
              :items="tableData.items"
              :options.sync="paginationOptions"
              :server-items-length="tableData.itemCount"
              :footer-props="{
                itemsPerPageOptions: paginationOptions.itemsRange,
                showCurrentPage: true,
                showFirstLastPage: true,
                disablePagination: loadingData,
                disableItemsPerPage: loadingData
              }"
            >
              <template v-slot:item.status="{ item }">
                <status-chip
                  small
                  :status="item.status"
                />
              </template>
              <template v-slot:item.docType="{ item }">
                {{ $t('documentCategories.' + item.docType) }}
              </template>
              <template v-slot:item.docDate="{ item }">
                {{ getDateTimeValue(item.docDate, 'short') }}
              </template>
              <template v-slot:item.createdAt="{ item }">
                {{ getDateTimeValue(item.createdAt, 'long') }}
              </template>
              <template v-slot:item.payment_date="{ item }">
                {{ getDateTimeValue(item.payment_date, 'short') }}
              </template>
              <template v-slot:item.total_amount="{ item }">
                <span v-if="$isNumber(item.total_amount)">
                  {{ item.total_amount | formatAndLocalize }}
                </span>
              </template>
              <template v-slot:item.vat_amount="{ item }">
                <span v-if="$isNumber(item.vat_amount)">
                  {{ item.vat_amount | formatAndLocalize }}
                </span>
              </template>
              <template v-slot:item.payment_method="{ item }">
                <span v-if="item.payment_method">
                  {{ $t('paymentMethods.' + item.payment_method) }}
                </span>
              </template>
              <template v-slot:item.actions="{ item }">
                <table-button
                  :link="{
                    name: 'DocumentsSentDetails',
                    params: {
                      workspaceId: $route.params.workspaceId,
                      routeDetailId: item.id
                    }
                  }"
                />
              </template>
            </v-data-table>
          </template>
        </card-layout>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import api from '@/api/modules/documents'
import { listViews } from '@/mixins/list_views'
import { getDateTimeValue } from '@/mixins/dates'
import CardLayout from '@/components/layout/CardLayout'
import StatusChip from '@/components/chips/StatusChip'
import SettingsSideSheet from '@/components/side_sheets/SettingsSideSheet'
import TableColumnsList from '@/components/settings/tables/ColumnsList'
import TableFiltersList from '@/components/settings/tables/FiltersList'
import FiltersSideSheet from '@/components/side_sheets/FiltersSideSheet'
import DocumentsListFilters from '@/components/filters/documents/Filters'
import FilterChips from '@/components/chips/FilterChips'
import TableToolbar from '@/components/toolbars/TableToolbar'
import TableButton from '@/components/buttons/TableButton'

export default {
  name: 'OrdersList',
  components: {
    CardLayout,
    StatusChip,
    SettingsSideSheet,
    FiltersSideSheet,
    TableColumnsList,
    TableFiltersList,
    DocumentsListFilters,
    FilterChips,
    TableToolbar,
    TableButton
  },
  mixins: [
    listViews,
    getDateTimeValue
  ],
  data () {
    return {
      selectedFilters: [],
      selectedHeaders: [],
      categoriesScope: ['purchase_order'],
      tableToolbarContext: {
        enableSettings: true
      }
    }
  },
  computed: {
    filters () {
      return [
        'supplier_name',
        'supplier_vat_id',
        'supplier_reference',
        'supplier_contact_name',
        'buyer_name',
        'buyer_vat_id',
        'buyer_reference',
        'buyer_contact_name',
        'po_reference',
        'invoicing_reference'
      ]
    },
    defaultHeaders () {
      return [
        { text: this.$t('general.details'), sortable: false, value: 'actions', width: 48 },
        { text: this.$t('documents.docNum'), value: 'docNum' },
        { text: this.$t('documents.docType'), value: 'docType' },
        { text: this.$t('general.status'), sortable: false, value: 'status' },
        { text: this.$t('documents.docDate'), value: 'docDate' },
        { text: this.$t('documents.supplier_name'), sortable: false, value: 'supplier_name' },
        { text: this.$t('documents.supplier_vat_id'), sortable: false, value: 'supplier_vat_id' },
        { text: this.$t('documents.createdAt'), value: 'createdAt' }
      ]
    },
    headers () {
      return [
        { text: this.$t('documents.completedAt'), value: 'completedAt' },
        { text: this.$t('documents.acquisitionPipeline'), sortable: false, value: 'acquisitionPipeline' },
        { text: this.$t('documents.integrationPipeline'), sortable: false, value: 'integrationPipeline' },
        { text: this.$t('documents.supplier_reference'), sortable: false, value: 'supplier_reference' },
        { text: this.$t('documents.supplier_contact_name'), sortable: false, value: 'supplier_contact_name' },
        { text: this.$t('documents.buyer_name'), sortable: false, value: 'buyer_name' },
        { text: this.$t('documents.buyer_vat_id'), sortable: false, value: 'buyer_vat_id' },
        { text: this.$t('documents.buyer_reference'), sortable: false, value: 'buyer_reference' },
        { text: this.$t('documents.buyer_contact_name'), sortable: false, value: 'buyer_contact_name' },
        { text: this.$t('documents.requestor'), sortable: false, value: 'requestor' },
        { text: this.$t('documents.deliver_to'), sortable: false, value: 'deliver_to' },
        { text: this.$t('documents.delivery_address'), sortable: false, value: 'delivery_address' },
        { text: this.$t('documents.payment_date'), sortable: false, value: 'payment_date' },
        { text: this.$t('documents.payment_period'), sortable: false, value: 'payment_period' },
        { text: this.$t('documents.payment_method'), sortable: false, value: 'payment_method' },
        { text: this.$t('documents.currency_code'), sortable: false, value: 'currency_code' },
        { text: this.$t('documents.total_amount'), sortable: false, value: 'total_amount', align: 'right' }
      ]
    },
    visibleHeaders () {
      const selected = this.headers.filter(header => {
        return this.selectedHeaders.find(selected => selected === header.value)
      })
      return this.defaultHeaders.concat(selected)
    }
  },
  methods: {
    async getTableData () {
      const params = {
        ...this.paginationOptions,
        activeFilters: Object.assign({}, this.activeFilters)
      }
      params.activeFilters.docType = this.categoriesScope
      this.loadingData = true
      const result = await api.getDocumentsList(params)
      Object.assign(this.tableData, result)
      this.loadingData = false
    }
  }
}
</script>
