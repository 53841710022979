<script>
import { VAutocomplete } from 'vuetify/lib'

export default {
  name: 'CustomAutocomplete',
  extends: VAutocomplete,
  props: {
    outlined: { type: Boolean, default: true }
  }
}
</script>
