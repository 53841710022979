<template>
  <v-col
    cols="12"
    sm="6"
    md="12"
    class="py-0"
  >
    <v-list>
      <v-list-group
        :prepend-icon="$vuetify.icons.values.filter"
      >
        <template v-slot:activator>
          <v-list-item-title>
            {{ $t('general.filters.title') }}
          </v-list-item-title>
        </template>
        <v-list-item-group
          v-model="localSelectedFilters"
          multiple
        >
          <template v-for="item in filters">
            <v-list-item
              :key="item"
              dense
              :value="item"
            >
              <template v-slot:default="{ active, toggle }">
                <v-list-item-action>
                  <v-checkbox
                    :input-value="active"
                    :true-value="item"
                    color="primary"
                    @click="toggle"
                  />
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t('documents.' + item) }}
                  </v-list-item-title>
                </v-list-item-content>
              </template>
            </v-list-item>
          </template>
        </v-list-item-group>
      </v-list-group>
    </v-list>
  </v-col>
</template>

<script>
export default {
  name: 'TableFiltersList',
  props: {
    filters: { type: Array, default: () => { return [] } },
    selectedFilters: { type: Array, default: () => { return [] } }
  },
  computed: {
    localSelectedFilters: {
      get () {
        return this.selectedFilters
      },
      set (list) {
        this.$emit('update:selected-filters', list)
        this.persistSelectedFilters(list)
      }
    }
  },
  mounted () {
    this.localSelectedFilters = JSON.parse(
      localStorage.getItem(
        this.$route.meta.viewContext +
        '-' +
        this.$route.params.documentCategory +
        '-filters-' +
        this.$route.params.workspaceId
      )
    ) || []
  },
  methods: {
    persistSelectedFilters (list) {
      localStorage.setItem(
        this.$route.meta.viewContext +
        '-' +
        this.$route.params.documentCategory +
        '-filters-' +
        this.$route.params.workspaceId, JSON.stringify(list)
      )
    }
  }
}
</script>
